import Network from 'lib/api/network';

export default {
  namespaced: true,

  state() {
    return {
      identity: null,
      canConnectGreenhouse: false,
      apiCredentials: {
        url: null,
        apiKey: null,
      },
      validApiKey: false,
      loading: false,
      errors: null,
    };
  },

  getters: {
    permissionsList() {
      return [
        {
          name: 'Applications',
          fields: [
            'Get: Retrieve application',
            'Get: List applications',
          ],
        },
        {
          name: 'Candidates',
          fields: [
            'Get: Retrieve candidate',
            'Get: List candidates',
          ],
        },
        {
          name: 'Jobs',
          fields: [
            'Get: Retrieve job',
            'Get: List jobs',
            'Get: Get hiring team',
          ],
        },
        {
          name: 'Offers',
          fields: [
            'Get: List application\'s offers',
            'Get: Retrieve application\'s current offer',
            'Get: Retrieve offer',
            'Get: List offers',
          ],
        },
        {
          name: 'Users',
          fields: [
            'Get: Retrieve user',
          ],
        },
      ];
    },
  },

  mutations: {
    update(state, { key, value }) {
      state[key] = value;
    },
  },

  actions: {
    track(_context, { eventName, properties }) {
      const fullEventName = `ATS Connect: ${eventName}`;
      const fullProperties = properties || {};
      window.mixpanel.track(fullEventName, fullProperties);
      window.FS.event(fullEventName); // ignore fullProperties
      window.Intercom('trackEvent', fullEventName);
    },

    openConnectModal({ commit }) {
      commit('updateModal', {
        visible: true,
        subcomponent: 'ats-connect-modals-auth-url',
      }, { root: true });
    },

    createDirectIntegrationIdentity({ state, commit }, modalArgs) {
      state.loading = true;
      const params = {
        direct_integration_identity: {
          integration_name: 'greenhouse',
          api_key: state.apiCredentials.apiKey,
        },
      };
      return new Promise((resolve, reject) => {
        Network.post(
          '/direct_integration_identities',
          {
            ...params,
          },
          {
            success: (response) => {
              state.identity = response.direct_integration_identity;
              commit('updateModal', modalArgs, { root: true });
              resolve(response.direct_integration_identity);
            },
            error: (response) => {
              commit('update', { key: 'errors', value: response.errors });
              reject();
            },
            complete: () => {
              state.loading = false;
            },
          },
        );
      });
    },

    validateApiKey({ state }) {
      state.validApiKey = false;
      return new Promise((resolve, reject) => {
        Network.get(`/direct_integration_identities/${state.identity.id}/validate`, {
          success: (response) => {
            state.identity = response.direct_integration_identity;
            state.validApiKey = true;
            resolve(true);
          },
          error: (response) => {
            state.validApiKey = false;
            state.errors = response.errors;
            reject(response.errors);
          },
        });
      });
    },

    pollForIdentityState({ state }) {
      if (!state.identity) {
        return;
      }

      state.loading = true;
      let timeoutId;
      const poll = () => {
        Network.get(`/direct_integration_identities/${state.identity.id}`, {
          success: (response) => {
            state.identity = response.direct_integration_identity;
            if (response.direct_integration_identity.state === 'active') {
              state.loading = false;
            } else {
              timeoutId = setTimeout(poll, 5000);
            }
          },
          error: (response) => {
            state.loading = false;
          },
        });
      };
      poll();
      return () => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
      };
    },
  },
};
